<template>
  <div class="container">
    <div class="section">
      <div class="columns is-multiline">
        <div class="column is-12 title-section">
          <h1>Detalle del usuario</h1>
          <div class="buttons" v-if="!isEdit">
            <button
              class="button is-danger"
              @click="deleteUser"
            >Eliminar</button>
            <button
              v-if="!isEdit"
              class="button is-primary"
              @click="activeEdit"
            >Editar</button>
          </div>
          <div class="buttons" v-if="isEdit">
            <button
            class="button is-primary is-outlined"
            @click="cancelEdit"
            :disabled="loadingUpdate"
          >Cancelar</button>
          <button
            class="button is-primary"
            :disabled="loadingUpdate"
            :class="{'is-loading': loadingUpdate}"
            @click="updateUser"
          >Guardar</button>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <div class="columns is-multiline" v-if="!loading">
              <div class="column is-12">
                <h1 class="title-card">Información del usuario</h1>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Nombre (s)</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.name"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.name}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Primer apellido</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.lastName"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.lastName}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Segundo apellido</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.secondLastName"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.secondLastName}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Correo electrónico</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.email"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.email}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Estatus</label>
                  <div class="control">
                    <div class="select is-fullwidth" v-if="isEdit">
                      <select v-model="newInfo.active">
                        <option :value="true">Activo</option>
                        <option :value="false">Inactivo</option>
                      </select>
                    </div>
                    <span v-else>
                      {{user.active ? 'Activo' : 'Inactivo'}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-multiline" v-if="loading">
              <div class="column is-12 has-text-centered">
                <div class="lds-dual-ring" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/libs/api'
export default {
  name: 'Usuarios',
  data () {
    return {
      isEdit: false,
      user: {},
      newInfo: {},
      loading: false,
      loadingUpdate: false
    }
  },
  methods: {
    activeEdit () {
      this.newInfo = { ...this.user }
      this.isEdit = !this.isEdit
    },
    cancelEdit () {
      this.newInfo = {}
      this.isEdit = !this.isEdit
    },
    async updateUser () {
      this.loadingUpdate = true
      const userId = parseInt(this.$route.params.usuarioId, 10)
      const data = {
        name: this.newInfo.name,
        last_name: this.newInfo.lastName,
        second_last_name: this.newInfo.secondLastName,
        active: this.newInfo.active ? 1 : 0
      }
      const response = await api.updateUsuario(userId, data)
      if (response.success) {
        this.user = this.newInfo
        this.isEdit = !this.isEdit
      }
      this.loadingUpdate = false
    },
    async getUser (userId) {
      const response = await api.getUsuario(userId)
      if (response.success) {
        this.user = response.payload
      }
    },
    async deleteUser () {
      const userId = parseInt(this.$route.params.usuarioId, 10)
      const response = await api.deleteUsuario(userId)
      if (response.success) {
        this.$router.push({ name: 'usuarios' })
      }
      console.log(response)
    }
  },
  mounted () {
    this.loading = true
    const userId = parseInt(this.$route.params.usuarioId, 10)
    this.getUser(userId)
      .then(_ => {
        this.loading = false
      })
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding-bottom: 3rem;
  .label {
    font-size: 14px;
    text-align: left;
    margin-left: 0.5rem;
  }
  .control {
    span {
      text-align: left;
      width: 100%;
      display: block;
      padding-left: 1rem;
      font-size: 13px;
    }
  }
}
</style>
